import React, {useState, useEffect} from 'react';
import "../../assets/css/style.css";
import Nav from '../components/nav.js';
import 'bootstrap/dist/css/bootstrap.css';
import { useForm, Controller } from "react-hook-form";
import content from "./content.js";
import dropdowns from './dropdowns.js';
import apicalls from '../../utils/apicalls.js';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import config from '../../config/index.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const marketingTeamDD = dropdowns.marketingTeam();
const countryDD = dropdowns.country();
const integratedCampaignProgramName = dropdowns.integratedCampaignProgramName();
const salesTheaterList = dropdowns.salesTheaterList();
const salesTheaterLead = dropdowns.salesTheaterLead();
const campaignThemeDD = dropdowns.campaignTheme();
const segmentDD = dropdowns.segment();
const integratedCampaignNameDD = dropdowns.integratedCampaignName();
const campaignTypeDD = dropdowns.campaignType();

const download = (data)=> { 
	// Creating a Blob for having a csv file format  
	// and passing the data with type 
	const blob = new Blob([data], { type: 'application/csv' }); 

	// Creating an object for downloading url 
	const url = URL.createObjectURL(blob) 
	// Creating an anchor(a) tag of HTML 
	const a = document.createElement('a') 

	// Passing the blob downloading url  
	a.download='Error List.csv';
	a.href=url
	document.body.appendChild(a);
	a.click();
	a.remove();
	URL.revokeObjectURL(url)
} 

const get = async (data)=> { 
	let errArr = data.split(",");
	// JavaScript object 
	let json = errArr.map(function (item, row) { return {Error: item }; });
	let data1 ={json};
	const headers = Object.keys(data1.json[0]).toString();
	const main = data1.json.map((items1)=>{
		return Object.values(items1).toString();
	})
	const csv = [headers, ...main].join('\n');
	download(csv);
} 

const PopupModalBodyResp = ({apiresponse}) => {
	if(apiresponse === "") {
		return  <div className="">
			<div className="text-center">
				<div className="d-flex align-items-center">
					<strong>Please Wait Loading...</strong>
				<div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
				</div>
			</div>
		</div>
	} else {
		return apiresponse;
	}
}

const PopupModalActionBtn = ({apiresponse, handleClose}) => {
	if(apiresponse === "") {
		return "";
	} 
	return <Button variant="secondary" className="btn-success text-center w-100" onClick={handleClose}>Ok</Button>
}

const PopupModalTitle = ({statusCode}) => {
	if(statusCode == "" || statusCode == "200") {
		return <p className='text-success m-0'>Thank you for Submitting the Form</p>
	} else if (statusCode == 500) {
		return <p className='text-danger text-center m-0'>Please try after sometime</p>
	} else {
		return <p className='text-danger text-center m-0'>Please check your details & resubmit the form</p>
	}
}

const PopupModal = ({show, apiresponse, statusCode, handleClose}) => {
	return <Modal show={show}  
		size="sm"
		aria-labelledby="contained-modal-title-vcenter"
		centered>
			<Modal.Header>
				<Modal.Title className='fs-6 text text-success'><PopupModalTitle statusCode={statusCode} /></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PopupModalBodyResp apiresponse={apiresponse} />
			</Modal.Body>
			<Modal.Footer className='d-block w-100'>
				<PopupModalActionBtn apiresponse={apiresponse} handleClose={handleClose} />
			</Modal.Footer>
	</Modal>
}

export default function EloquaCampaign({page, session}) {
	const [MarketingTeam, setMarketingTeam] = useState(null);
	const [IsLoadingCampaignNameList, setIsLoadingCampaignNameList] = useState(true);
	const [Region, setRegion] = useState(null);
	const [Country, setCountry] = useState(null);
	const [SalesTheaterList, setSalesTheaterList] = useState([]);
	const [CampaignType, setCampaignType] = useState([]);
	const [CampaignTheme, setCampaignTheme] = useState(null);
	const [ChannelName, setChannelName] = useState(null);
	const [Segment, setSegment] = useState(null);
	const [ShowSalesTheaterLeadOthers, setShowSalesTheaterLeadOthers] = useState(false);
	const [ShowSegmentOther, setShowSegmentOther] = useState(false);
	const [ShowIntegratedCampaignName, setShowIntegratedCampaignName] = useState(false);
	const [IntegratedCampaignProgramName, setIntegratedCampaignProgramName] = useState([]);
	const [ParentCampaignName, setParentCampaignName] = useState(null);
	const [IntegratedCampaignName, setIntegratedCampaignName] = useState(null);
	const [printContent,setprintContent] = useState("");
	const [submitbtndisable,setSubmitBtnDisable] = useState(false);
	const [Refresh,setRefresh] = useState(false);
	const handleClose = () => setShow(false);
	const [apiresponse,setApiResponse] = useState();
	const [statusCode,setStatusCode] = useState("");
	const [show, setShow] = useState(false);
	const fileuploadurl = `${config.services.url.restAPI[config.services.environment]}/eloquaintegrations/api/campaignfieldsvalues/`
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue
	} = useForm();

	const fillDDValues = async (fn, state) => {
		let opts = [];
		fn.forEach(e => {
			opts.push({value: e, label: e})
		}); 
		state(opts)
	}

	const getCountryValues = async () => {
		let countryArr = [];
		countryDD.forEach(ele => {
			ele.country.forEach(val => {
				countryArr.push({value: `${ele.region} -|- ${val}`, label: val})
			});
		});
		countryArr.push({value: 'N/A -|- N/A', label: 'N/A'})
		setCountry(countryArr);
	}
	
	const getCampaingType = async () => {
		let campaignTypeArr = [];
		campaignTypeDD.forEach((ele, i) => {
			campaignTypeArr.push({value: `${ele.channel} -|- ${i}`, label: ele.label})
		});
		setCampaignType(campaignTypeArr);
	}

	const getSalesTheaterList = async (r = '') => {
		let nRegion = [];
		// if(r) {
		// 	salesTheaterList[r].salesTheaterLead.forEach(ele => {
		// 		nRegion.push({value: ele, label: ele})
		// 	});
		// 	setSalesTheaterList(nRegion)
		// }
		salesTheaterLead.forEach(ele => {
			nRegion.push({value: ele.trim(), label: ele.trim()})
		})
		setSalesTheaterList(nRegion)
	}

	const initApi = async (data) => {
		setSubmitBtnDisable(true);
		let DateOfCampaignEnd = ''; 
		let DateOfCampaignLaunch = ''; 
		if(data && data.hasOwnProperty('dateOfCampaignEnd') && data.dateOfCampaignEnd !== '') {
			DateOfCampaignEnd = moment(data.dateOfCampaignEnd).format('MM/DD/YYYY');
		}

		if(data && data.hasOwnProperty('dateOfCampaignLaunch') && data.dateOfCampaignLaunch !== '') {
			DateOfCampaignLaunch = moment(data.dateOfCampaignLaunch).format('MM/DD/YYYY');
		}

		const formData = new FormData();
		formData.append("BusinessSponsor",data.businessSponsor || '')				
		formData.append("MarketingTeam",data.marketingTeam.value || '')
		formData.append("CampaignName",data.campaignName || '')
		formData.append("CampaignDescription",data.campaignDescription || '')
		formData.append("Country",data.Country || '')
		formData.append("Region",Region)
		formData.append("salesTheaterList",data.SalesTheaterLeadOthers || data.fieldSalesTheaterLead.value || '')
		formData.append("CampaignTheme",data.CampaignTheme.value || '')
		formData.append("Segment",data.segmentOthers || data.Segment.value || '')
		formData.append("ParentCampaignId",data.ParentCampaignName || '')
		formData.append("IntegratedCampaignName", data.integratedCampaignNameOthers || data.IntegratedCampaignName.value || '')
		formData.append("integratedCampaignProgramName", data.integratedCampaignProgramNameField.value || '')
		formData.append("CampaignType",data.CampaignType.label || '')
		formData.append("ChannelName", ChannelName || '')
		// formData.append("recurrenceType",data.RecurrenceType.value || '')
		formData.append("DateOfCampaignLaunch",DateOfCampaignLaunch || '')
		formData.append("DateOfCampaignEnd",DateOfCampaignEnd || '')
		formData.append("ExpectedVolumeLeads",data.expectedVolumeLeads || '')
		formData.append("IntentExpectedOutcome",data.expectedOutcome || '')

		let apiResp = await apicalls.postCall(fileuploadurl, formData, session.token,'Token','', {
			'Content-Type': 'application/json'
		})
		if (apiResp.status) {
			setShow(true);
			setStatusCode(apiResp.statusCode);
			setSubmitBtnDisable(false);
			setApiResponse("Your request has been processed successfully.");
		} else {
			setShow(true);
			let text = apiResp.context; 
			setApiResponse(text);
			setStatusCode(apiResp.statusCode);
			setSubmitBtnDisable(false);
		}
	}

	const onSubmit = async (data) => {
		initApi(data)
	}

	// update onchange react-hook-form component
	const updateChangeEvent = (value, field) => {
		field.onChange(value);
		if(field.name === "Segment" && value.value === "Other") {
			setShowSegmentOther(true);
		} else if (field.name === "Segment" && value.value !== "Other") {
			setValue('segmentOthers', '');
			setShowSegmentOther(false);
		}
		
		if(field.name === "IntegratedCampaignName" && value.value === "Other") {
			setValue('integratedCampaignProgramNameField', '');
			setIntegratedCampaignProgramName([]);
			setShowIntegratedCampaignName(true);
		} else if (field.name === "IntegratedCampaignName" && value.value !== "Other") {
			if(value.value === "Reimagine Work (UCaaS/Suite)") {
				setIntegratedCampaignProgramName(integratedCampaignProgramName.reimagineWork)
			} else if(value.value === "Reimagine Workspaces (Devices)") {
				setIntegratedCampaignProgramName(integratedCampaignProgramName.reimagineWorkspaces)
			} else if(value.value === "Customer Experience (CCaaS / Contact Center)") {
				setIntegratedCampaignProgramName(integratedCampaignProgramName.customerExperience)
			} else {
				setValue('integratedCampaignProgramNameField', '');
				setIntegratedCampaignProgramName([]);
			}
			setValue('integratedCampaignNameOthers', '');
			setShowIntegratedCampaignName(false);
		}

		if(field.name === "fieldSalesTheaterLead" && value.value === "Other") {
			setShowSalesTheaterLeadOthers(true);
		} else if (field.name === "fieldSalesTheaterLead" && value.value !== "Other") {
			setValue('SalesTheaterLeadOthers', '');
			setShowSalesTheaterLeadOthers(false);
		}

		if(field.name === "CampaignType") {
			let v = value.value;
			let sv = v.split('-|-');
			setChannelName(sv[0]);
		} 
	}

	const updateCountryEvent = (value, field) => {
		let r = value.value.split('-|-');
		setRegion(r[0].replace(/\s/g,''));
		updateChangeEvent(r[1].trim(), field);
	}

	const FallBackMsg = () => {
		if(IsLoadingCampaignNameList) {
			return <div className="spinner-border spinner-border-sm" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		} else if (typeof ParentCampaignName === 'string') {
			return <div>{ParentCampaignName}</div>
		} else {
			return null;
		}
	}

	// const getCampaignNames = async () => {
	// 	setIsLoadingCampaignNameList(true);
	// 	let apiResp = await apicalls.postCall(`${config.services.url.restAPI[config.services.environment]}/eloquaintegrations/api/getcampaignnamelist/`, '', session.token,'Token' ,'', {
	// 		'Content-Type': 'application/json'
	// 	})
	// 	if(apiResp.statusCode == 200) {
	// 		let opts = [];
	// 		if(apiResp.context.Campaign_name && Array.isArray(apiResp.context.Campaign_name) && apiResp.context.Campaign_name.length > 0) {
	// 			apiResp.context.Campaign_name.map(ele => {
	// 				opts.push({value: ele, label: ele})
	// 			});
	// 			setParentCampaignName(opts);
	// 			setIsLoadingCampaignNameList(false);
	// 		}
	// 	} else {
	// 		let m = "Not able to fetch the records. Please try again";
	// 		if(apiResp.hasOwnProperty('context')) {
	// 			m = apiResp.context;
	// 		}
	// 		setParentCampaignName(m);
	// 		setIsLoadingCampaignNameList(false);
	// 	}
	// }

	useEffect(() => {
		// setprintContent(content.fieldEloquaCName());
		fillDDValues(marketingTeamDD, setMarketingTeam);
		fillDDValues(campaignThemeDD, setCampaignTheme);
		fillDDValues(segmentDD, setSegment);
		fillDDValues(integratedCampaignNameDD, setIntegratedCampaignName);
		getCampaingType();
		getCountryValues();
		// getCampaignNames();
		getSalesTheaterList();
	}, []);

	useEffect(() => {
		setValue('fieldSalesTheaterLead', '');
		getSalesTheaterList(Region);
	}, [Region])

	var twoWeeks = 1000 * 60 * 60 * 24 * 14;
	var twoWeeksTime = new Date(new Date().getTime() + twoWeeks);

	return(
		<>
			<div className="container-fluid" style={{background: '#000'}}>
				<div className="container">
					{/* nav */}
					<Nav page={page} session={session} />
					{/* form */}
					<div className="row ">
						<div className="col">
							<div style={{minwidth: '30%', maxWidth: '30%'}}>
								<div className='float-start position-fixed overflow-hidden px-3 pb-2 description-area' style={{maxWidth: 'inherit'}}>
									{printContent}
								</div>
							</div>
							<div className="float-end" style={{width: '60%'}}>
								<h2 className="text-center text-white"></h2>
								<div className="formbold-main-wrapper mb-5 mt-3">
									<div className="formbold-form-wrapper">
										<h4 className="mb-4 mt-1 text-center">Eloqua Campaign Request</h4>
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="mb-3">
												<label htmlFor="businessSponsor" className="fw-bold formbold-form-label fw-light">Business Sponsor</label>
												<input {...register('businessSponsor')} className="formbold-form-input fw-light" placeholder="Business Sponsor" onClick={e => setprintContent(content.returnDefault(''))} />
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Marketing Team*</label>
												{(MarketingTeam && Array.isArray(MarketingTeam) && MarketingTeam.length > 0) ? <Controller
													name='marketingTeam'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={MarketingTeam} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/> : "Loading"}
												{errors.marketingTeam && <p className='text-danger fs-6 fw-light'>Marketing Team filed is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campaignName" className="fw-bold formbold-form-label fw-light">Campaign Name*</label>
												<input {...register('campaignName', { required: true })} className="formbold-form-input fw-light" placeholder="Campaign Name" onClick={e => setprintContent(content.returnDefault(''))} />
												{errors.campaignName && <p className='text-danger fs-6 fw-light'>Campaign Name is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campaignDescription" className="fw-bold formbold-form-label fw-light">Campaign Description*</label>
												<input maxLength={1000} {...register('campaignDescription', { required: true, maxLength: '1000' })} className="formbold-form-input fw-light" placeholder="Campaign Description" onClick={e => setprintContent(content.returnDefault(''))} />
												{errors.campaignDescription && <p className='text-danger fs-6 fw-light'>Campaign Description is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Country*</label>
												{(Country && Array.isArray(Country) && Country.length > 0) ? <Controller
													name='Country'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateCountryEvent(e, field)}
															options={Country} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/> : "Loading"}
												{errors.Country && <p className='text-danger fs-6 fw-light'>Country is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Region*</label>
												{Region ? `Selected Region: ${Region}` : 'Please choose country'}
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Sales Theater Lead*</label>
												{(SalesTheaterList && Array.isArray(SalesTheaterList) && SalesTheaterList.length > 0) ? <Controller
													name='fieldSalesTheaterLead'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={SalesTheaterList} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/> : "Choose Country to pick Sales Theater Lead"}
												{errors.fieldSalesTheaterLead && <p className='text-danger fs-6 fw-light'>Sales Theater Lead is required</p>}
											</div>
											{ShowSalesTheaterLeadOthers? <div>
												<div className="mb-3">
													<label htmlFor="SalesTheaterLeadOthers" className="fw-bold formbold-form-label fw-light">Sales Theater Lead (Others)</label>
													<input {...register('SalesTheaterLeadOthers', { required: true })} className="formbold-form-input fw-light" placeholder="Sales Theater Lead (Others)" onClick={e => setprintContent(content.returnDefault(''))} />
												</div>
											</div> : null}
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Campaign Theme*</label>
												{(CampaignTheme && Array.isArray(CampaignTheme) && CampaignTheme.length > 0) ? <Controller
													name='CampaignTheme'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={CampaignTheme} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/> : "Loading"}
												{errors.CampaignTheme && <p className='text-danger fs-6 fw-light'>Campaign Theme is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Segment*</label>
												{(Segment && Array.isArray(Segment) && Segment.length > 0) ? <Controller
													name='Segment'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={Segment} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/> : "Loading"}
												{errors.Segment && <p className='text-danger fs-6 fw-light'>Segment filed is required</p>}
											</div>
											{ShowSegmentOther ? <div>
												<div className="mb-3">
													<label htmlFor="segmentOthers" className="fw-bold formbold-form-label fw-light">Segment (Others)</label>
													<input {...register('segmentOthers', { required: true })} className="formbold-form-input fw-light" placeholder="Segment Others" onClick={e => setprintContent(content.returnDefault(''))} />
												</div>
											</div> : null}
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Parent Campaign Id</label>
												<input {...register('ParentCampaignName', { required: false })} className="formbold-form-input fw-light" placeholder="Parent Campaign Id" onClick={e => setprintContent(content.returnDefault(''))} />
												{errors.ParentCampaignName && <p className='text-danger fs-6 fw-light'>Parent Campaign Id filed is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Integrated Campaign Name/Premium Event</label>
												{(IntegratedCampaignName && Array.isArray(IntegratedCampaignName) && IntegratedCampaignName.length > 0) ? <Controller
													name='IntegratedCampaignName'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={IntegratedCampaignName} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/> : "Loading"}
												{errors.IntegratedCampaignName && <p className='text-danger fs-6 fw-light'>Integrated Campaign Name filed is required</p>}
											</div>
											{ShowIntegratedCampaignName ? <div>
												<div className="mb-3">
													<label htmlFor="integratedCampaignNameOthers" className="fw-bold formbold-form-label fw-light">Integrated Campaign Name (Others)</label>
													<input {...register('integratedCampaignNameOthers', { required: true })} className="formbold-form-input fw-light" placeholder="Integrated Campaign Name" onClick={e => setprintContent(content.returnDefault(''))} />
												</div>
											</div> : null}
											{(IntegratedCampaignProgramName && Array.isArray(IntegratedCampaignProgramName) && IntegratedCampaignProgramName.length > 0) ? <div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Integrated Campaign Program Name</label>
												<Controller
													name='integratedCampaignProgramNameField'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={IntegratedCampaignProgramName} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/>
												{errors.IntegratedCampaignProgramName && <p className='text-danger fs-6 fw-light'>Integrated Campaign Program Name is required</p>}
											</div> : null}
											<div className="mb-3">
												<label htmlFor="campagin-type" className="fw-bold formbold-form-label fw-light">Campaign Type</label>
												{(CampaignType && Array.isArray(CampaignType) && CampaignType.length > 0) ? <Controller
													name='CampaignType'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<Select
															defaultValue={field.value}
															onChange={(e) => updateChangeEvent(e, field)}
															options={CampaignType} 
															className='custom-select-container '
															classNamePrefix="custom-select" 
															onMenuOpen={e => setprintContent(content.returnDefault(''))}
														/>
													)}
												/> : "Loading"}
												{errors.CampaignType && <p className='text-danger fs-6 fw-light'>Campaign Type filed is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="channelName" className="fw-bold formbold-form-label fw-light">Channel Name</label>
												{ChannelName ? `Selected Channel Name: ${ChannelName}` : 'Please choose Campaign Type'}
											</div>
											<div className="mb-3">
												<label htmlFor="createdate" className="fw-bold formbold-form-label fw-light">Date of Campaign Launch</label>
												<Controller
													name='dateOfCampaignLaunch'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<DatePicker minDate={new Date()} selected={field.value} className="formbold-form-input" onCalendarOpen={() => setprintContent(content.returnDefault(''))} onChange={(e) => {
															updateChangeEvent(e, field)
														}} />
													)}
												/>
												{errors.dateOfCampaignLaunch && <p className='text-danger fs-6 fw-light'>Date of event is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="createdate" className="fw-bold formbold-form-label fw-light">Date of Campaign End</label>
												<Controller
													name='dateOfCampaignEnd'
													rules={{ required: true }}
													control={control}
													render={({ field }) => (
														<DatePicker minDate={twoWeeksTime} selected={field.value} className="formbold-form-input" onCalendarOpen={() => setprintContent(content.returnDefault(''))} onChange={(e) => {
															updateChangeEvent(e, field)
														}} />
													)}
												/>
												{errors.dateOfCampaignEnd && <p className='text-danger fs-6 fw-light'>Date of event is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="expectedVolumeLeads" className="fw-bold formbold-form-label fw-light">Expected Volume of Leads</label>
												<input {...register('expectedVolumeLeads', { required: false })} className="formbold-form-input fw-light" placeholder="Expected Volume of Leads" onClick={e => setprintContent(content.returnDefault(''))} />
												{errors.expectedVolumeLeads && <p className='text-danger fs-6 fw-light'>Expected Volume of Leads is required</p>}
											</div>
											<div className="mb-3">
												<label htmlFor="expectedOutcome" className="fw-bold formbold-form-label fw-light">Intent, Success Metrics & Expected Outcome</label>
												<input {...register('expectedOutcome', { required: false })} className="formbold-form-input fw-light" placeholder="Intent, Success Metrics & Expected Outcome" onClick={e => setprintContent(content.returnDefault(''))} />
												{errors.expectedOutcome && <p className='text-danger fs-6 fw-light'>Expected Volume of Leads is required</p>}
											</div>
											<div>
												<input type="submit" id="sendfiles" value={submitbtndisable ? "Loading" : "Submit"} className="btn btn-lg btn-success w-100" disabled={submitbtndisable ? "disabled" : ""} />
											</div>
										</form>
										<PopupModal show={show} apiresponse={apiresponse} statusCode={statusCode} handleClose={handleClose} />
									</div>
								</div>
							</div> 
						</div>
					</div>
				</div>
			</div>
		</>
	)
}